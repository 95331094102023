<template>
  <v-container :height="this.best_height" :width="this.best_widht" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm8 md4>
        <v-form v-model="valid" ref="itemForm" @submit="item_submit">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.mobile"
                label="Celular"
                hide-details="auto"
                dense
                type="number"
                :rules="f_required"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-select
                v-model="item.type_id"
                :items="type_id"
                label="Tipo de documento"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="item.identification_id"
                label="Numero de documento"
                hide-details="auto"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="item.name"
                id="name"
                :rules="f_required"
                label="Nombre"
                hide-details="auto"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.email"
                label="Correo Electrónico de contacto"
                :rules="f_required"
                hide-details="auto"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.shipping_email"
                label="Correo Electrónico Facturación"
                :rules="f_required"
                hide-details="auto"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="item.address"
                :rules="f_required"
                label="Dirección Principal"
                hide-details="auto"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="item.city"
                hide-details="auto"
                label="Ciudad"
                :items="cities"
                item-text="name"
                item-value="code"
                :rules="f_required"
                outlined
                required
                dense
              />
            </v-col>

            <v-col cols="12">
              <template>
                <v-toolbar class="mt-6" tile flat>
                  <v-toolbar-title>Otras direcciones de entrega</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn fab x-small color="success" @click="dialog = !dialog">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list width="100%" elevation="2">
                  <v-list-item v-for="(branch, index) in branches" :key="index">
                    <v-list-item-content>
                      <b>{{ branch.name }}</b>
                      <br />
                      <span>{{ branch.address + " " + branch.city }}</span>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn icon x-small @click="deleteItem(branch)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </template>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn :disabled="!valid" type="submit">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-bottom-navigation grow app> </v-bottom-navigation> -->
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="600" transition="dialog-bottom-transition">
      <v-form v-model="validAddress" ref="addAddress" @submit="addAddressToAddress">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title> Dirección de entrega</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
          </v-toolbar>
          <v-card-text>
            <v-row class="pt-3">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="branch.name"
                  id="name"
                  :rules="f_required"
                  label="Nombre"
                  hide-details="auto"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="branch.address"
                  :rules="f_required"
                  label="Dirección"
                  hide-details="auto"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="branch.city"
                  hide-details="auto"
                  label="Ciudad"
                  :items="cities"
                  item-text="name"
                  item-value="code"
                  :rules="f_required"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!validAddress" type="submit">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createParty.js";
import cities_lst from "../../utils/colCities.js";

export default {
  name: "PartyForm",
  components: {},
  data() {
    return {
      dialog: false,
      edit: false,
      menuPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      productPhoto: null,
      viewMap: false,
      recenter: false,
      ipgeo: null,
      cities: cities_lst().Colombia,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      type_id: [
        { value: "13", text: "Cédula de ciudadanía" },
        { value: "31", text: "NIT" },
        { value: "11", text: "Registro civil" },
        { value: "12", text: "Tarjeta de identidad" },
        { value: "21", text: "Tarjeta de extranjería" },
        { value: "22", text: "Cédula de extranjería" },
        { value: "41", text: "Pasaporte" },
        { value: "42", text: "Documento de identificación extranjero" },
        { value: "50", text: "NIT de otro país" },
      ],
      stloc: null,
      table: "clients",
      item: createItem(),
      newItem: false,
      validAddress: false,
      branches: [],
      branch: {
        name: null,
        address: null,
        city: cities_lst().Colombia,
      },
      best_height: "100%",
      best_widht: "500",
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.onResize();
    this.$store.dispatch("setNavStatus", true);
    var perfil = window.localStorage.getItem("profile");
    if (perfil) {
      var itm = JSON.parse(
        this.CryptoJS.AES.decrypt(perfil, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      if (itm.shipping_address) {
        this.branches = JSON.parse(itm.shipping_address);
      }

      this.item = itm;

      this.branch.city = itm.city;
      if (itm.mobile) {
        this.$store.dispatch("setProfile", itm);
      } else {
        //this.$router.push("/Registration");
      }
    } else {
      this.$router.push("/Registration");
    }
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 500;
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    deleteItem(e) {
      var index = this.branches.indexOf(e);
      if (index !== -1) {
        this.branches.splice(index, 1);
      }
    },
    addAddressToAddress(e) {
      e.preventDefault();
      this.validAddress = this.$refs.addAddress.validate();
      if (this.validAddress) {
        this.branches.push({ ...this.branch });
      }
      this.dialog = false;
    },
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    re_center() {
      this.recenter = !this.recenter;
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.item.account = this.$store.getters.company.account;
        if (this.branches.length > 0) {
          this.item.shipping_address = JSON.stringify(this.branches);
        } else {
          this.item.shipping_address = "";
        }

        var qry = {
          account: this.$store.getters.company.account,
          table: this.table,
          data: [this.item],
        };
        console.log(qry);
        webserver("put_table", qry, (data) => {
          const encryptedText = this.CryptoJS.AES.encrypt(
            JSON.stringify(data),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("profile", encryptedText);
          this.$router.push("/");
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", false);
    },
    onDecode(e) {
      this.stopCamera = true;
      this.QRdialog = false;
      this.item.qr = e;
    },
    openQR() {
      this.stopCamera = false;
      this.QRdialog = true;
    },
  },
  watch: {},
};
</script>

<style>
.v-btn--example {
  top: 10px;
  position: absolute;
  margin: 0 0 0 0;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.map {
  height: 400px;
}

.btn-upload {
  top: 150px;
}
</style>
